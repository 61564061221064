// ------------------------------
// flex
// ------------------------------

.l-flex{
    display: flex;
}
.l-flex--wrap{
    flex-wrap: wrap;
}
.l-flex--grow{
    flex: 1;
}
.l-flex--between{
    justify-content: space-between;
}
.l-flex--center{
    justify-content: center;
}
.l-flex--left{
    justify-content: flex-start;
}
.l-flex--right{
    justify-content: flex-end;
}
.l-flex--middle{
    align-items: center;
}
.l-flex--top{
    align-items: flex-start;
}
.l-flex--bottom{
    align-items: flex-end;
}

@media screen and (max-width: $breakPoint){
    .l-flex{
    }
}
