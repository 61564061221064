// ------------------------------
// col
// ------------------------------

.l-col{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &--center{
        align-items: center;
    }
    &--1{
        @extend .l-col;
        .l-col__item{
            width: 100%;
            margin: 0 auto 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &--2{
        @extend .l-col;
        .l-col__item{
            width: calc(50% - 8px);
            margin: 0 16px 16px 0;
            &:nth-child(2n){
                margin-right: 0;
            }
            &:nth-last-child(1),
            &:nth-last-child(2){
                margin-bottom: 0;
            }
        }
    }
    &--2s{
        @extend .l-col;
        .l-col__item{
            width: calc(50% - 4px);
            margin: 0 8px 8px 0;
            &:nth-child(2n){
                margin-right: 0;
            }
            &:nth-last-child(1),
            &:nth-last-child(2){
                margin-bottom: 0;
            }
        }
    }
    &--3{
        @extend .l-col;
        .l-col__item{
            width: calc(33.33% - 10.7px);
            margin: 0 16px 16px 0;
            &:nth-child(3n){
                margin-right: 0;
            }
            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3){
                margin-bottom: 0;
            }
        }
    }
    &--4{
        @extend .l-col;
        .l-col__item{
            width: calc(25% - 12px);
            margin: 0 16px 16px 0;
            &:nth-child(4n){
                margin-right: 0;
            }
            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4){
                margin-bottom: 0;
            }
        }
    }
    &--5{
        @extend .l-col;
        .l-col__item{
            width: calc(20% - 12.8px);
            margin: 0 16px 16px 0;
            &:nth-child(5n){
                margin-right: 0;
            }
            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4),
            &:nth-last-child(5){
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: $breakPoint){
    .l-col{
        display: block;
        &--2{
            .l-col__item{
                width: 100%;
                margin: 0 auto 16px;
                &:nth-child(2n){
                    margin-right: auto;
                }
                &:nth-last-child(1),
                &:nth-last-child(2){
                    margin-bottom: 16px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &--2s{
            display: flex;
        }
        &--3{
            .l-col__item{
                width: 100%;
                margin: 0 auto 16px;
                &:nth-child(3n){
                    margin-right: auto;
                }
                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3){
                    margin-bottom: 16px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &--4{
            .l-col__item{
                width: 100%;
                margin: 0 auto 16px;
                &:nth-child(4n){
                    margin-right: auto;
                }
                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3),
                &:nth-last-child(4){
                    margin-bottom: 16px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &--5{
            .l-col__item{
                width: 100%;
                margin: 0 auto 16px;
                &:nth-child(5n){
                    margin-right: auto;
                }
                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3),
                &:nth-last-child(4),
                &:nth-last-child(5){
                    margin-bottom: 16px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
