
@use "@infragistics/igniteui-angular/theming" as *;
@import "./global.scss";
// Uncomment the following lines if you want to add a custom palette:
// $primary: #731963 !default;
// $secondary: #ce5712 !default;
// $app-palette: palette($primary, $secondary, $surface);

/* autoprefixer grid: on */

@include core();
@include typography($font-family: $material-typeface, $type-scale: $material-type-scale);
@include theme($default-palette);
// CSS Reset, comment out if not required or using a different module
@import 'minireset.css/minireset';
/* You can add global styles to this file, and also import other style files */
