//
// mixin
// - scss内で使用するmixin
//

// 10px未満のfont-size指定
@mixin fz-small($scale: .8, $origin: left top){
    font-size: 10px;
    transform: scale($scale);
    transform-origin: $origin;
    width: 10 / $scale * 10 * 1%;
}

// transition
@mixin transition($property: all, $duration: .2s, $delay: 0, $ease: $ease-1){
    transition-property: $property;
    transition-duration: $duration;
    transition-delay: $delay;
    transition-timing-function: $ease;
}

// 上下中央揃え
@mixin center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// hover
@mixin hover($defautColor: $color-black, $defautBgcolor: transparent, $color: $defautColor, $bgcolor: $defautBgcolor, $opacity: 1){
    color: $defautColor;
    background-color: $defautBgcolor;
    &:hover{
        color: $color;
        background-color: $bgcolor;
        opacity: $opacity;
    }
    @media screen and (max-width: $breakPoint){
        &:hover{
            color: $defautColor;
            background-color: $defautBgcolor;
            opacity: 1;
        }
    }
}

// placeholder
@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}
