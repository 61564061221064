// ------------------------------
// base
// ------------------------------

body {
  font-size: 62.5%;
}
body {
  min-width: $site-w-min;
  background: $color-gray0;
  color: $color-gray900;
  line-height: 1.66;
  @extend .c-text-3;
  font-family: YuGothic, "Yu Gothic", "Hiragino Sans", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-all;
}
a {
  outline: none;
  text-decoration: none;
  color: $color-gray700;
  @include transition;
  &:hover {
    color: $color-pink-hover;
  }
  &.is-disable {
    color: $color-gray300;
    pointer-events: none;
  }
}
img {
  width: 100%;
  height: auto;
  user-select: none;
}
hr {
  border: none;
  border-top: $color-gray300 solid 1px;
}

@media screen and (max-width: $breakPoint) {
  a {
    &:hover {
      opacity: 1;
    }
  }
}
