// ------------------------------
// btn
// ------------------------------

.c-btn {
  cursor: pointer;
  a {
    display: inline-block;
    text-align: center;
    padding: 3px 11px;
    border-radius: 4px;
    min-width: 141px;
    @extend .c-text-4;
    @extend .c-text-bold;
    &:hover {
      opacity: 0.7;
    }
  }
  span,
  i {
    display: inline-block;
    vertical-align: middle;
  }
  i {
    margin-right: 4px;
    @extend .c-text-3;
  }
  &--disable a {
    pointer-events: none;
    background: $color-gray400;
    color: $color-gray700;
  }
  &--white a {
    background: $color-gray0;
    border: 1px solid $color-gray300;
    color: $color-gray700;
    &:hover {
      opacity: 1;
      background: $color-gray200;
    }
  }
  &--pink a {
    background: $color-pink;
    border: 1px solid $color-pink;
    color: $color-gray0;
    &:hover {
      opacity: 1;
      background: $color-pink-hover;
      border: 1px solid $color-pink-hover;
      color: $color-gray0;
    }
  }
  &--pink-light a {
    background: $color-pink-light;
    border: 1px solid $color-pink;
    color: $color-pink;
  }
  &--pink-text {
    a {
      color: $color-pink;
      &:hover {
        color: $color-pink-hover;
      }
    }
  }
  &--blue a {
    background: $color-blue;
    border: 1px solid $color-blue;
    color: $color-gray0;
    &:hover {
      opacity: 1;
      background: $color-blue-hover;
      border: 1px solid $color-blue-hover;
      color: $color-gray0;
    }
  }
  &--blue-light a {
    background: $color-blue-light;
    border: 1px solid $color-blue;
    color: $color-pink;
  }
  &--blue-text {
    a {
      color: $color-blue;
      &:hover {
        color: $color-blue-hover;
      }
    }
  }
  &--gray a {
    background: $color-gray300;
    &:hover {
      color: $color-gray700;
    }
  }
  &--tooltip {
    &:hover {
      opacity: 1;
      color: $color-pink;
    }
  }
  &--close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: $color-gray900;
      i {
        font-size: 20px;
        color: $color-gray0;
      }
      &:hover {
        background: $color-pink;
      }
    }
  }
  &--lg a {
    padding: 8px 40px;
  }
  &--145 a {
    width: 145px;
    padding: 8px;
  }
}
.c-btn--s {
  @extend .c-btn;
  padding: 0.22em 1em;
  i {
    font-size: 1.2em;
  }
}

@media screen and (max-width: $breakPoint) {
}
