// ------------------------------
// text
// ------------------------------

.c-text-left{
    text-align: left;
}
.c-text-right{
    text-align: right;
}
.c-text-center{
    text-align: center;
}
.c-text-1{
    @extend .c-text-bold;
    line-height: 1;
    font-size: 24px;
}
.c-text-2{
    @extend .c-text-bold;
    font-size: 18px;
}
.c-text-3{
    font-size: 16px;
}
.c-text-4{
    font-size: 13px;
}
.c-text-5{
    font-size: 12px;
}
.c-text-6{
    font-size: 11px;
}
.c-text-7{
    font-size: 10px;
}
.c-text-bold{
    font-weight: 700;
}
.c-text-regular{
    font-weight: 400;
}
.c-text-link{
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}

@media screen and (max-width: $breakPoint){}
