// ------------------------------
// form
// ------------------------------

.is-firefox {
  .c-form {
    &__input,
    &__textarea {
      padding: 0.1em 0.5em;
      &--s {
        padding: 0.05em 0.5em;
      }
      &--ss {
        line-height: 1.3;
        padding: 0 0.4em;
      }
    }
  }
}

.c-form {
  &__input,
  &__textarea,
  &__select {
    width: 100%;
    padding: 0.2em 0.5em;
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.6;
    display: block;
    outline: none;
    background: $color-gray0;
    border: $color-gray700 solid 1px;
    &.is-error {
      background: $color-gray0 !important;
      border-color: $color-negative;
    }
    &--s {
      font-size: 12px;
      padding: 0.15em 0.5em;
    }
    &--ss {
      font-size: 12px;
      padding: 0 0.4em;
    }
  }
  &__error {
    color: $color-negative;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 0.5em;
    display: block;
    &:before {
      content: "\e922";
      font-size: 12px;
      font-weight: normal;
      top: -0.1em;
      margin-right: 0.2em;
      font-family: "intersevre-icons" !important;
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__add {
    margin-top: 0.4em;
    a {
      font-size: 13px;
      text-decoration: none;
      i {
        font-size: 18px;
        top: -0.1em;
        margin-right: 0.2em;
        display: inline-block;
        vertical-align: middle;
        position: relative;
      }
    }
    &--l {
      a {
        font-size: 15px;
        font-weight: 700;
        i {
          top: -0.05em;
        }
      }
    }
  }
  &__added {
    display: flex;
    align-items: center;
  }
  &__inputs {
    & > * {
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
    }
    &--flex {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      & + .c-form__inputs--flex {
        margin-top: 12px;
      }
    }
    &--head {
      min-width: 100px;
    }
    &--body {
      flex-grow: 1;
    }
  }
  &__delete {
    font-size: 24px;
    margin-left: 0.4em;
    line-height: 1;
    color: $color-gray400;
    &:hover {
      color: $color-negative;
    }
  }
  &__input {
    @include placeholderColor($color-gray400);
    &:required {
      background: #fffbee;
    }
    &.is-not-blank {
      background: $color-gray0;
    }
    &--120 {
      max-width: 120px;
    }
    &--240 {
      max-width: 240px;
    }
    &--360 {
      max-width: 360px;
    }
    &--half {
      max-width: 50%;
    }
    &--flex1 {
      flex: 1;
      max-width: none;
    }
    &--blue {
      border-color: $color-blue;
    }
    &--year {
      width: 80px;
    }
    &--month,
    &--day {
      width: 40px;
    }
  }
  &__textarea {
    min-height: 200px;
    &:required {
      background: #fffbee;
    }
    &.is-not-blank {
      background: $color-gray0;
    }
  }
  &__select {
    padding-right: 30px;
    box-shadow: none;
    border-color: $color-gray700;
    &--s {
      padding-right: 24px;
    }
    &:required {
      background: #fffbee;
    }
    &.is-not-blank {
      background: $color-gray0;
    }
  }
  &__selectbox {
    position: relative;
    &:after {
      content: "\e905";
      font-size: 20px;
      top: 50%;
      right: 6px;
      z-index: 2;
      line-height: 1;
      transform: translateY(-50%);
      font-family: "intersevre-icons" !important;
      display: inline-block;
      position: absolute;
      color: $color-gray700;
      pointer-events: none;
    }
    &--max {
      max-width: 400px;
    }
    &--clear {
      select {
        border-color: transparent;
      }
    }
    &--blue {
      select {
        border-color: $color-blue;
      }
    }
    &--s {
      &:after {
        font-size: 16px;
        right: 4px;
      }
    }
    &.is-disable {
      select {
        pointer-events: none;
        color: $color-gray400;
        border-color: $color-gray400;
      }
      &:after {
        color: $color-gray400;
      }
    }
  }
  &__password {
    position: relative;
    .c-form__input {
      padding-right: 40px;
    }
    .c-form__showpass {
      font-size: 25px;
      top: 50%;
      right: 8px;
      padding: 0;
      z-index: 2;
      line-height: 1;
      transform: translateY(-50%);
      cursor: pointer;
      display: inline-block;
      position: absolute;
      color: $color-gray700;
      &:hover {
        opacity: 1;
      }
    }
    .c-form__showpass:after {
      content: "\e925";
      font-family: "intersevre-icons" !important;
    }
    &.is-password-show {
      .c-form__showpass:after {
        content: "\e927";
      }
    }
  }
  &__checkbox {
    padding: 0.4em 0;
    display: inline-block;
    cursor: pointer;
    input[type="checkbox"] {
      opacity: 0;
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      & + span:before {
        content: "";
        font-size: 18px;
        width: 1.22em;
        height: 1.22em;
        border-radius: 4px;
        margin-right: 0.5em;
        line-height: 1.22;
        top: -0.1em;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        font-family: "intersevre-icons" !important;
        background: $color-gray0;
        border: $color-gray300 solid 1px;
      }
      &:checked + span:before {
        content: "\e907";
        color: $color-gray0;
        background: $color-blue;
        border-color: $color-blue;
      }
    }
    &--s {
      input[type="checkbox"] {
        & + span:before {
          font-size: 12px;
          margin-right: 0.8em;
        }
      }
    }
    &--circle {
      padding: 0;
      line-height: 1;
      input[type="checkbox"] {
        & + span:before {
          font-size: 24px;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          margin-right: 0;
          top: -0.05em;
          line-height: 1;
        }
        &:checked + span:before {
          content: "\e921";
          color: $color-blue;
          background: transparent;
          border-color: transparent;
        }
      }
    }
    &--delete {
      input[type="checkbox"] {
        &:checked + span:before {
          content: "\e92e";
        }
      }
    }
    .c-texts--s {
      @extend .c-texts--s;
    }
  }
  &__radio {
    input[type="radio"] {
      opacity: 0;
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      & + span:before {
        box-sizing: border-box;
        content: "";
        font-size: 18px;
        width: 1.33em;
        height: 1.33em;
        border-radius: 50%;
        margin-right: 0.5em;
        top: -0.1em;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        border: $color-gray300 solid 1px;
      }
      &:checked + span:before {
        border: $color-blue solid 8px;
      }
    }
    &--s {
      input[type="radio"] {
        & + span:before {
          font-size: 12px;
          margin-right: 0.5em;
        }
        &:checked + span:before {
          border-width: 5px;
        }
      }
    }
  }
  &__checkbox2 {
    border-radius: 8px;
    transition: 0.3s;
    background: $color-gray0;
    border: $color-gray300 solid 1px;
    overflow: hidden;
    display: block;
    &:hover {
      background: $color-gray100;
    }
    input[type="checkbox"],
    input[type="radio"] {
      opacity: 0;
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      & + span:before {
        content: "";
        font-size: 16px;
        width: 1.38em;
        height: 1.38em;
        border-radius: 50%;
        margin-right: 0.8em;
        line-height: 1.38;
        top: 1.4rem;
        left: 1.5rem;
        text-align: center;
        display: inline-block;
        position: absolute;
        vertical-align: middle;
        font-family: "intersevre-icons" !important;
        background: $color-gray0;
        border: $color-gray300 solid 1px;
      }
      &:checked + span {
        color: $color-blue;
        border-color: $color-blue;
      }
      &:checked + span:before {
        content: "\e907";
        color: $color-gray0;
        background: $color-blue;
        border-color: $color-blue;
      }
    }
    & > span,
    & > label > span {
      padding: 0.9em 1em 0.9em 3.4em;
      display: block;
      position: relative;
      cursor: pointer;
    }
    &.is-checked {
      background: $color-blue-white;
      border-color: $color-blue;
    }
    &__child {
      display: none;
      .c-form {
        &__checkbox2 {
          padding: 1px;
          &.is-checked {
            padding: 0;
            background: $color-gray0;
            border-width: 2px;
          }
        }
      }
    }
    &--s {
      input[type="checkbox"],
      input[type="radio"] {
        & + span:before {
          font-size: 16px;
          margin-right: 0.8em;
          line-height: 1.38;
          top: 0.7rem;
          left: 0.7rem;
        }
      }
      & > span,
      & > label > span {
        padding: 0.9em 1em 0.9em 3.6rem;
      }
    }
    &--orange {
      input[type="checkbox"],
      input[type="radio"] {
        &:checked + span {
          color: $color-gray0;
        }
        &:checked + span:before {
          color: #f7a42b;
          background: $color-gray0;
          border-color: #f7a42b;
        }
      }
      &.is-checked {
        background: #f7a42b;
        border-color: #f7a42b;
      }
    }
  }
  &__file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--input {
      display: none;
    }
    &--text {
      flex-grow: 1;
      padding-right: 2em;
    }
    &--btn {
      @extend .c-btn--s;
      padding: 0.4em 1.8em;
      cursor: pointer;
    }
  }
  &__small {
    max-width: 240px;
  }
  &__date {
    width: 160px;
  }
  &__cal {
    font-size: 24px;
    transform: translateY(4%);
    display: inline-block;
    color: $color-gray700;
  }
  &__nospace {
    margin: 0 !important;
    padding: 0 !important;
    &:before,
    span:before {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: $breakPoint) {
  .c-form {
    &__small {
      max-width: none;
    }
  }
}
