// ------------------------------
// table
// ------------------------------

.c-modal{
    .l-table{
        &__item{
            &--service{
                & > .l-table__body{
                    .l-table__head{
                        width: 180px;
                        min-width: 180px;
                    }
                }
            }
        }
    }
}

.l-table{
    border: $color-gray300 solid 1px;
    &--blue{
        border: $color-blue solid 2px;
        .l-table{
            &__head{
                background: $color-blue-white;
            }
        }
    }
    &__item{
        display: flex;
        border-bottom: $color-gray300 solid 1px;
        &:last-child{
            border-bottom: none;
        }
        &--s{
            .l-table{
                &__head, &__body{
                    padding: 10px 16px;
                    &--input{
                        padding: 16px;
                    }
                }
            }
        }
        &--ss{
            font-size: 13px;
            input, input + span{
                font-size: 15px;
            }
            .l-table{
                &__head, &__body{
                    padding: 8px 12px;
                    &--input{
                        padding: 12px;
                    }
                }
            }
        }
        &--col2{
            .l-table{
                &__item{
                    width: 50%;
                    border-bottom: none;
                }
            }
        }
        &--col3{
            .l-table{
                &__item{
                    width: 33.33%;
                    border-bottom: none;
                }
            }
        }
        &--wrap{
            border-width: 2px;
            .l-table{
                &__head{
                    width: auto;
                    min-width: auto;
                    padding: 12px 16px;
                    align-items: stretch;
                    & > *{
                        width: 106px;
                    }
                }
                &__body{
                    padding: 0;
                    border-left: $color-gray300 solid 1px;
                    .l-table{
                        &__head{
                            width: 45%;
                            padding: 12px 16px;
                            align-items: stretch;
                            & > *{
                                width: auto;
                            }
                        }
                        &__body{
                            width: 55%;
                            padding: 0;
                            border-left: none;
                        }
                    }
                }
            }
        }
        &--service{
            border-width: 2px;
            & > .l-table__head{
                width: 160px;
                min-width: 160px;
                display: block;
                border-right: $color-gray300 solid 1px;
                &--2{
                    width: 300px;
                    min-width: 300px;
                }
                &--body{
                    width: auto;
                    flex: 1;
                    border-right: none;
                }
            }
            & > .l-table__body{
                padding: 0;
                .l-table__head{
                    width: 200px;
                    min-width: 200px;
                }
            }
            &.l-table__item--label{
                & > .l-table__head{
                    padding: 4px 12px;
                    background: $color-gray200;
                }
            }
        }
        &--head{
            padding: 6px 10px;
            justify-content: space-between;
            background: $color-blue-white;
            a{
                font-weight: 700;
                text-decoration: none;
                i{
                    font-size: 1.2em;
                    margin-right: .2em;
                    transform: translateY(-10%);
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
    &__head, &__body{
        padding: 12px;
    }
    &__head{
        // width: 240px;
        min-width: 240px;
        font-weight: 700;
        display: flex;
        align-items: center;
        background: $color-gray100;
        p{
            font-size: 13px;
            line-height: 1.3;
            font-weight: 700;
        }
        &--sm{
            min-width: 125px;
        }
        &--wide{
            min-width: 320px;
        }
    }
    &__body{
        flex-grow: 1;
        p{
            font-size: 13px;
        }
        p + p{
            margin-top: .2em;
        }
    }
    &__names{
        display: flex;
        & + .l-table__names{
            margin-top: 8px;
        }
    }
    &__name{
        width: 50%;
        display: flex;
        align-items: center;
        label{
            width: 42px;
            padding-right: .6em;
            text-align: right;
            display: block;
            color: $color-gray700;
        }
        input{
            width: calc(100% - 42px);
        }
        &:first-child{
            padding-right: 6px;
        }
        &:last-child{
            padding-left: 6px;
        }
        &--yen{
            width: 55%;
            label{
                width: auto;
                color: $color-gray900;
            }
        }
    }
    &__btns{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -16px;
        &--inline{
            .l-table__btn{
                width: auto;
                margin-right: 4em;
            }
        }
    }
    &__btn{
        width: 50%;
        margin-bottom: 16px;
        &--auto{
            width: auto;
            margin-right: 5em;
        }
        &--block{
            width: 100%;
        }
    }
}

@media screen and (max-width: $site-w-pc){
    // .l-table{
    //     &__item{
    //         &--col2{
    //             display: block;
    //             .l-table{
    //                 &__item{
    //                     width: 100%;
    //                     border-bottom: $color-gray300 solid 1px;
    //                     &:last-child{
    //                         border-bottom: none;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

@media screen and (max-width: $breakPoint){

    .c-modal{
        .l-table{
            &__item{
                &--service{
                    & > .l-table__body{
                        .l-table__head{
                            width: auto;
                            min-width: auto;
                        }
                    }
                }
            }
        }
    }

    .l-table{
        border-left: none;
        border-right: none;
        margin-left: -16px;
        margin-right: -16px;
        &__item{
            display: block;
            &--s{
                .l-table{
                    &__head{
                        padding: 8px 16px;
                    }
                }
            }
            &--wrap{
                .l-table{
                    &__head{
                        padding: 8px 16px;
                        color: $color-gray0;
                        background: $color-gray700;
                        & > *{
                            width: auto;
                        }
                    }
                    &__body{
                        padding: 0;
                        border-left: none;
                        .l-table{
                            &__head{
                                width: 100%;
                                color: $color-gray900;
                                background: $color-gray100;
                            }
                            &__body{
                                width: 100%;
                            }
                        }
                    }
                }
            }
            &--service{
                & > .l-table__head{
                    width: auto;
                    min-width: auto;
                    border-right: none;
                    border-bottom: $color-gray300 solid 1px;
                }
                & > .l-table__body{
                    .l-table__head{
                        width: auto;
                        min-width: auto;
                    }
                }
                &.l-table__item--label{
                    display: none;
                }
            }
        }
        &__head{
            padding: 12px 16px;
            width: 100%;
            display: block;
            min-width: auto;
        }
        &__names{
            display: block;
        }
        &__name{
            width: 100%;
            &:first-child{
                padding-right: 0;
            }
            &:last-child{
                padding-left: 0;
            }
            & + .l-table__name{
                margin-top: 8px;
            }
        }
        &__btns{
            display: block;
            margin-bottom: 0;
            &--inline{
                .l-table__btn{
                    margin-right: 0;
                }
            }
        }
        &__btn{
            width: 100%;
            &:last-child{
                margin-bottom: 0;
            }
            &--auto{
                width: 100%;
                margin-right: auto;
            }
        }
    }
}
