// ------------------------------
// vertical
// ------------------------------

.h-vertical-top{
    vertical-align: top !important;
}
.h-vertical-bottom{
    vertical-align: bottom !important;
}
.h-vertical-middle{
    vertical-align: middle !important;
}