// ------------------------------
// padding
// ------------------------------

$paddings: 48, 36, 24, 16, 12, 8, 6, 0;

@each $padding in $paddings {
    $n: index($paddings, $padding);

    .h-padding-#{$padding}{
        padding: #{$padding}px;
    }
    .h-padding-side#{$padding}{
        padding: 0 #{$padding}px;
    }
    .h-padding-t#{$padding}{
        padding-top: #{$padding}px;
    }
    .h-padding-b#{$padding}{
        padding-bottom: #{$padding}px;
    }
}

@media screen and (max-width: $breakPoint){}
