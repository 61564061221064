/**
 *
 * GOBAL SCSS
 * - setting-scss配下のscssファイルを読み込む
 *
 */

/**
 *
 * SETTING
 * - scssの設定ファイル
 *
 */
@import "./app/scss/_setting/_var";
@import "./app/scss/_setting/_mixin";

/**
 *
 * FOUNDATION
 * - htmlのタグに直にあてるスタイル
 *
 */
@import "./app/scss/_foundation/_reset";
@import "./app/scss/_foundation/_base";

/**
 *
 * HELPER
 * - 補助的に使用するクラス
 *
 * @prefix h-
 *
 */
@import "./app/scss/_helper/_border";
@import "./app/scss/_helper/_color";
@import "./app/scss/_helper/_display";
@import "./app/scss/_helper/_margin";
@import "./app/scss/_helper/_padding";
@import "./app/scss/_helper/_position";
@import "./app/scss/_helper/_vertical";
@import "./app/scss/_helper/_width";

/**
 *
 * JS
 * - トリガーなど、jsのセレクタとなるクラス、またそのスタイル
 *
 * @prefix js-
 *
 */
@import "./app/scss/_js/_accordion";
@import "./app/scss/_js/_panel";

/**
 *
 * LAYOUT
 * - レイアウトに使用するクラス
 *
 * @prefix l-
 *
 */
@import "./app/scss/_layout/_col";
@import "./app/scss/_layout/_flex";
@import "./app/scss/_layout/_main";
@import "./app/scss/_layout/_table";

/**
 *
 * COMPONENT
 * - プロジェクト全体で使い回す要素・ブロックのクラス
 *
 * @prefix c-
 *
 */
@import "./app/scss/_component/_badge";
@import "./app/scss/_component/_btn";
@import "./app/scss/_component/_floating";
@import "./app/scss/_component/_form";
@import "./app/scss/_component/_icon";
@import "./app/scss/_component/_text";
