// ------------------------------
// color
// ------------------------------

.h-color-gray0{
    color: $color-gray0;
}
.h-color-gray100{
    color: $color-gray100;
}
.h-color-gray200{
    color: $color-gray200;
}
.h-color-gray300{
    color: $color-gray300;
}
.h-color-gray400{
    color: $color-gray400;
}
.h-color-gray600{
    color: $color-gray600;
}
.h-color-gray700{
    color: $color-gray700;
}
.h-color-gray900{
    color: $color-gray900;
}
.h-color-blue{
    color: $color-blue;
}
.h-color-sky{
    color: $color-sky;
}
.h-color-yellow{
    color: $color-yellow;
}
.h-color-red{
    color: $color-red;
}
.h-color-pink{
    color: $color-pink;
}
.h-color-pink-hover{
    color: $color-pink-hover;
}
.h-color-pink-light{
    color: $color-pink-light;
}
