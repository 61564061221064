// ------------------------------
// badge
// ------------------------------

.c-badge{
    display: inline-block;
    text-align: center;
    border-radius: 12px;
    padding: 4px 8px;
    @extend .c-text-bold;
    line-height: 1;
    color: $color-gray0;
    &--red{
        background: $color-red;
    }
    &--yellow{
        background: $color-yellow;
    }
    &--green{
        background: $color-green;
    }
    &--sky{
        background: $color-sky;
    }
    &--blue{
        background: $color-blue;
    }
    &--pink{
        background: $color-pink;
    }
    &--gray{
        background: $color-gray700;
    }
    &--gray-light{
        background: $color-gray200;
        color: $color-gray700;
    }
    &--sm{
        padding: 2px 5px;
        @extend .c-text-4;
    }
    &--box{
        padding: 5px;
        border-radius: 4px;
        font-size: 10px;
    }
}

@media screen and (max-width: $breakPoint){}
