// ------------------------------
// width
// ------------------------------

@for $i from 0 through 300 {
    .h-width-#{$i}{
        width: #{$i}px !important;
    }
}
@for $i from 0 through 100 {
    .h-width-p#{$i}{
        width: #{$i * 1%} !important;
    }
}

@media screen and (max-width: $breakPoint){}