// ------------------------------
// main
// ------------------------------

.l-main{
    padding: 48px 0 0 260px;
    //min-width: 1024px;
    &__contents{
        padding: 24px;
    }
}

@media screen and (max-width: $breakPointWide){
    .l-main{
        padding: 48px 0 0 !important;
    }
}

@media screen and (max-width: $breakPoint){
    .l-main{
        &__contents{
            width: 100% !important;
            padding: 32px 16px 48px;
            min-height: auto;
        }
    }
}
