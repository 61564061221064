.c-floating{
    position: fixed;
    z-index: 99;
    bottom: 0;
    right: 0;
    width: calc(100% - 260px);

    //storybook上でナビの開閉を再現するため追記
    &.is-open{
        width: calc(100% - 260px);
    }
    &.is-close{
        width: 100%;
    }

    .c-nav{
        &.is-open{
            + .l-main{
                .c-floating{
                    width: calc(100% - 260px) !important;
                }
            }
        }
    }

}

@media screen and (max-width: $breakPointWide){
    .c-floating{
        width: 100% !important;
    }
}
