// ------------------------------
// margin
// ------------------------------

$margins: 88, 56, 48, 40, 36, 32, 28, 24, 16, 12, 8, 4;

@each $margin in $margins {
    $n: index($margins, $margin);

    .h-margin-#{$margin}{
        margin: #{$margin}px 0;
    }
    .h-margin-side#{$margin}{
        margin: 0 #{$margin}px;
    }
    .h-margin-t#{$margin}{
        margin-top: #{$margin}px;
    }
    .h-margin-b#{$margin}{
        margin-bottom: #{$margin}px;
    }
    .h-margin-l#{$margin}{
        margin-left: #{$margin}px;
    }
    .h-margin-r#{$margin}{
        margin-right: #{$margin}px;
    }
}

@media screen and (max-width: $breakPoint){}
