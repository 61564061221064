@font-face {
  font-family: 'intersevre-icons';
  src:  url('../../../assets/fonts/intersevre-icons.eot?ut2de');
  src:  url('../../../assets/fonts/intersevre-icons.eot?ut2de#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/intersevre-icons.ttf?ut2de') format('truetype'),
    url('../../../assets/fonts/intersevre-icons.woff?ut2de') format('woff'),
    url('../../../assets/fonts/intersevre-icons.svg?ut2de#intersevre-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="c-icon-"], [class*=" c-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'intersevre-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c-icon-link:before {
  content: "\e952";
}
.c-icon-trash:before {
  content: "\e951";
}
.c-icon-database:before {
  content: "\e950";
}
.c-icon-web-edi:before {
  content: "\e94f";
}
.c-icon-add:before {
  content: "\e900";
}
.c-icon-alert:before {
  content: "\e901";
}
.c-icon-arrow-bottom:before {
  content: "\e902";
}
.c-icon-arrow-left:before {
  content: "\e903";
}
.c-icon-arrow-right:before {
  content: "\e904";
}
.c-icon-arrow-s-bottom:before {
  content: "\e905";
}
.c-icon-arrow-s-left:before {
  content: "\e906";
}
.c-icon-arrow-s-right:before {
  content: "\e907";
}
.c-icon-arrow-s-top:before {
  content: "\e908";
}
.c-icon-arrow-top:before {
  content: "\e909";
}
.c-icon-attention-fill:before {
  content: "\e90a";
}
.c-icon-attention:before {
  content: "\e90b";
}
.c-icon-bad:before {
  content: "\e90c";
}
.c-icon-calendar:before {
  content: "\e90d";
}
.c-icon-change:before {
  content: "\e90e";
}
.c-icon-check:before {
  content: "\e90f";
}
.c-icon-circle:before {
  content: "\e910";
}
.c-icon-circle2:before {
  content: "\e911";
}
.c-icon-circle3:before {
  content: "\e912";
}
.c-icon-close:before {
  content: "\e913";
}
.c-icon-comment:before {
  content: "\e914";
}
.c-icon-comments:before {
  content: "\e915";
}
.c-icon-company:before {
  content: "\e916";
}
.c-icon-complete:before {
  content: "\e917";
}
.c-icon-delete:before {
  content: "\e918";
}
.c-icon-document:before {
  content: "\e919";
}
.c-icon-down:before {
  content: "\e91a";
}
.c-icon-download:before {
  content: "\e91b";
}
.c-icon-edit:before {
  content: "\e91c";
}
.c-icon-external-link:before {
  content: "\e91d";
}
.c-icon-fav-line:before {
  content: "\e91e";
}
.c-icon-fav:before {
  content: "\e91f";
}
.c-icon-fax:before {
  content: "\e920";
}
.c-icon-furiwake:before {
  content: "\e921";
}
.c-icon-good:before {
  content: "\e922";
}
.c-icon-group:before {
  content: "\e923";
}
.c-icon-handshake:before {
  content: "\e924";
}
.c-icon-help:before {
  content: "\e925";
}
.c-icon-help2:before {
  content: "\e926";
}
.c-icon-home:before {
  content: "\e927";
}
.c-icon-information:before {
  content: "\e928";
}
.c-icon-invisible:before {
  content: "\e929";
}
.c-icon-jpy:before {
  content: "\e92a";
}
.c-icon-juchu:before {
  content: "\e92b";
}
.c-icon-key:before {
  content: "\e92c";
}
.c-icon-list:before {
  content: "\e92d";
}
.c-icon-login:before {
  content: "\e92e";
}
.c-icon-logout:before {
  content: "\e92f";
}
.c-icon-mail:before {
  content: "\e930";
}
.c-icon-menu:before {
  content: "\e931";
}
.c-icon-mifuriwake:before {
  content: "\e932";
}
.c-icon-minus:before {
  content: "\e933";
}
.c-icon-navigation:before {
  content: "\e934";
}
.c-icon-news:before {
  content: "\e935";
}
.c-icon-notification:before {
  content: "\e936";
}
.c-icon-nouhin:before {
  content: "\e937";
}
.c-icon-payment:before {
  content: "\e938";
}
.c-icon-performance:before {
  content: "\e939";
}
.c-icon-plan:before {
  content: "\e93a";
}
.c-icon-plus:before {
  content: "\e93b";
}
.c-icon-reject:before {
  content: "\e93c";
}
.c-icon-reload:before {
  content: "\e93d";
}
.c-icon-right:before {
  content: "\e93e";
}
.c-icon-search:before {
  content: "\e93f";
}
.c-icon-service:before {
  content: "\e940";
}
.c-icon-setting:before {
  content: "\e941";
}
.c-icon-shukka:before {
  content: "\e942";
}
.c-icon-simulation:before {
  content: "\e943";
}
.c-icon-status:before {
  content: "\e944";
}
.c-icon-suggestion:before {
  content: "\e945";
}
.c-icon-support:before {
  content: "\e946";
}
.c-icon-swap:before {
  content: "\e947";
}
.c-icon-tel:before {
  content: "\e948";
}
.c-icon-top-a:before {
  content: "\e949";
}
.c-icon-top-b:before {
  content: "\e94a";
}
.c-icon-up:before {
  content: "\e94b";
}
.c-icon-user-fill:before {
  content: "\e94c";
}
.c-icon-user:before {
  content: "\e94d";
}
.c-icon-visible:before {
  content: "\e94e";
}
