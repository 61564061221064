//
// var
// - scss内で使用する変数
//

// ---------- size ----------
$site-w-min: 320px;
$site-w-pc: 1366px;
$site-w-sp: 375px;
$breakPointWide: 1193px;
$breakPoint: 768px;

// ---------- color ----------
$color-gray0: #ffffff;
$color-gray100: #f8f8f8;
$color-gray200: #f0f0f0;
$color-gray300: #dddddd;
$color-gray400: #cccccc;
$color-gray500: #AAAEB3;
$color-gray600: #707070;
$color-gray700: #737b80;
$color-gray900: #32393d;
// $color-blue: #157AD1;
$color-blue: #1976D2;
$color-blue-hover: #64acf4;
$color-blue-light: #b5d3f1;
$color-blue-light2: #ccdef1;
$color-blue-white: #EFF7FF;
$color-sky: #69CEE0;
$color-green: #3AB796;
$color-yellow: #E8A952;
$color-red: #ED3E45;
// $color-pink: #AD6A72;
$color-pink: #E91E63;
$color-pink-hover: #e59da6;
$color-pink-light: #F2DCDF;
$color-pink-light2: #F4E9EB;
$color-pink-white: #FFF8F9;
$color-pink-clear: #FFF8F9;
$color-negative: #ed3d45;

// ---------- ease ----------
$ease-1: ease-out;
